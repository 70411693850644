var exports = {};
exports = {
  A: {
    D: {
      "2": "I r J D E F A B C K L G M N O s t u",
      "33": "0 1 2 3 4 5 6 7 8 9 v w x y z AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB QB RB SB TB UB VB rB WB sB XB YB ZB aB bB cB dB eB fB gB d hB iB jB kB lB mB P Q R S T U V W X Y Z a b e f g h i j k l m n o p q c H uB vB AC BC"
    },
    L: {
      "33": "H"
    },
    B: {
      "2": "C K L G M N O",
      "33": "P Q R S T U V W X Y Z a b e f g h i j k l m n o p q c H"
    },
    C: {
      "2": "7B",
      "33": "0 1 2 3 4 5 6 7 8 9 qB I r J D E F A B C K L G M N O s t u v w x y z AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB QB RB SB TB UB VB rB WB sB XB YB ZB aB bB cB dB eB fB gB d hB iB jB kB lB mB P Q R tB S T U V W X Y Z a b e f g h i j k l m n o p q c H uB vB 8B 9B"
    },
    M: {
      "33": "c"
    },
    A: {
      "2": "J D E F A B 6B"
    },
    F: {
      "2": "F B C LC MC NC OC nB 4B PC oB",
      "33": "0 1 2 3 4 5 6 7 8 9 G M N O s t u v w x y z AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB d hB iB jB kB lB mB P Q R tB S T U V W X Y Z a b"
    },
    K: {
      "2": "A B C nB 4B oB",
      "33": "d"
    },
    E: {
      "2": "I r J CC wB DC EC KC",
      "33": "D E F A B C K L G FC GC xB nB oB yB HC IC zB 0B 1B 2B pB 3B JC"
    },
    G: {
      "2": "wB QC 5B RC SC",
      "33": "E TC UC VC WC XC YC ZC aC bC cC dC eC fC gC hC iC jC zB 0B 1B 2B pB 3B"
    },
    P: {
      "2": "I",
      "33": "sC tC uC vC wC xB xC yC zC 0C 1C pB 2C 3C 4C"
    },
    I: {
      "2": "qB I lC mC nC oC 5B",
      "33": "H pC qC"
    }
  },
  B: 6,
  C: "width: stretch property"
};
export default exports;